import { keyMirror } from '@gilbarbara/helpers';

import { Status } from 'types';

export const ActionTypes = keyMirror({
  GITHUB_GET_REPOS_REQUEST: undefined,
  GITHUB_GET_REPOS_SUCCESS: undefined,
  GITHUB_GET_REPOS_FAILURE: undefined,
  HIDE_ALERT: undefined,
  SHOW_ALERT: undefined,
  SET_APP_OPTIONS: undefined,
  USER_LOGIN_REQUEST: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,
  USER_LOGOUT_REQUEST: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,
  USER_GET_LOGGINED_USER_INFO: undefined,
  RESERVATION_SEARCH: undefined,
  RESERVATION_SEARCH_FINISH: undefined,
  RESERVATION_SEARCH_RESET: undefined,
  RESERVATION_CREATE: undefined,
  RESERVATION_CREATE_SUCCESS: undefined,
  RESERVATION_CREATE_RESET: undefined,
  RESERVATION_CREATE_FAILURE: undefined,
  RESERVATION_UPDATE: undefined,
  RESERVATION_UPDATE_SUCCESS: undefined,
  RESERVATION_UPDATE_FAILURE: undefined,
  RESERVATION_GET: undefined,
  RESERVATION_GET_FINISH: undefined,
  RESERVATION_RESET: undefined,
  RESERVATION_GET_DETAIL: undefined,
  RESERVATION_GET_DETAIL_FINISH: undefined,
  RESERVATION_GET_DETAIL_RESET: undefined,
  RESERVATION_NUMBER_GET: undefined,
  RESERVATION_NUMBER_GET_FINISH: undefined,
  ROOM_SEARCH: undefined,
  ROOM_SEARCH_FINISH: undefined,
  ROOM_SEARCH_RESET: undefined,
  ROOM_TYPE_GET: undefined,
  ROOM_TYPE_GET_FINISH: undefined,
  ROOM_OPTION_GET: undefined,
  ROOM_OPTION_GET_FINISH: undefined,
  PRODUCT_SEARCH: undefined,
  PRODUCT_SEARCH_FINISH: undefined,
  AGENT_INFOS_GET: undefined,
  AGENT_INFOS_GET_FINISH: undefined,
  RESERVATION_RATE_UPDATE: undefined,
  RESERVATION_RATE_UPDATE_SUCCESS: undefined,
  RESERVATION_RATE_UPDATE_FAILURE: undefined,
  RESERVATION_BOOK_ROOM: undefined,
  RESERVATION_BOOK_ROOM_SUCCESS: undefined,
  RESERVATION_BOOK_ROOM_FAILURE: undefined,
  RESERVATION_ADD_RESERVATION_DETAIL: undefined,
  RESERVATION_ADD_RESERVATION_DETAIL_SUCCESS: undefined,
  RESERVATION_CANCEL_RESERVATION_DETAIL: undefined,
  RESERVATION_CANCEL_RESERVATION_DETAIL_SUCCESS: undefined,
  RESERVATION_GENERAL_INFO_UPDATE: undefined,
  RESERVATION_GENERAL_INFO_SUCCESS: undefined,
  RESERVATION_GENERAL_INFO_FAILURE: undefined,
  RESERVATION_FETCH_SCHEDULE: undefined,
  RESERVATION_FETCH_SCHEDULE_SUCCESS: undefined,
  RESERVATION_FETCH_AVAILABLE_SCHEDULE: undefined,
  RESERVATION_FETCH_AVAILABLE_SCHEDULE_SUCCESS: undefined,
  PRODUCT_TYPE_GET: undefined,
  PRODUCT_TYPE_GET_FINISH: undefined,
  CHANNEL_FETCH: undefined,
  CHANNEL_FETCH_SUCCESS: undefined,
  TRANSACTION_ADD_ITEM: undefined,
  TRANSACTION_ADD_ITEM_SUCCESS: undefined,
  TRANSACTION_ADD_DISK: undefined,
  TRANSACTION_ADD_DISK_SUCCESS: undefined,
  TRANSACTION_DELETE_ITEM: undefined,
  TRANSACTION_DELETE_ITEM_SUCCESS: undefined,
  PAYMENT_CREATE: undefined,
  PAYMENT_CREATE_SUCCESS: undefined,
  TRANSACTION_CHANGE_DISK: undefined,
  TRANSACTION_CHANGE_DISK_SUCCESS: undefined,
  GUEST_CREATE: undefined,
  GUEST_CREATE_SUCCESS: undefined,
  GUEST_CREATE_FAILURE: undefined,
  GUEST_UPDATE: undefined,
  GUEST_UPDATE_SUCCESS: undefined,
  GUEST_REMOVE: undefined,
  GUEST_REMOVE_SUCCESS: undefined,
  CUSTOMER_SEARCH: undefined,
  CUSTOMER_SEARCH_FINISH: undefined,
  RESERVATION_DETAIL_UPDATE_NOTE: undefined,
  RESERVATION_DETAIL_UPDATE_NOTE_SUCCESS: undefined,
  RESERVATION_DETAIL_UPDATE_NOTE_FAILURE: undefined,
  RESERVATION_RESEND_EMAIL: undefined,
  RESERVATION_RESEND_EMAIL_SUCCESS: undefined,
  FILE_UPLOAD: undefined,
  FILE_UPLOAD_SUCCESS: undefined,
  GET_LANGUAGE_CODE: undefined,
  GET_LANGUAGE_CODE_FINISH: undefined,
  GET_ROOMS: undefined,
  GET_ROOMS_FINISH: undefined,
  GET_WALKIN_ROOMS: undefined,
  GET_WALKIN_ROOMS_FINISH: undefined,
  UPDATE_ROOM_AVAILABLE_NUMBER: undefined,
  UPDATE_ROOM_AVAILABLE_NUMBER_SUCCESS: undefined,
  RESERVATION_DETAIL_DOWNLOAD_PDF: undefined,
  RESERVATION_DETAIL_DOWNLOAD_PDF_SUCCESS: undefined,
  RESERVATION_DETAIL_DOWNLOAD_PDF_FAILURE: undefined,
  SET_MAIN_GUEST: undefined,
  SET_MAIN_GUEST_SUCCESS: undefined,
  RESERVATION_DETAIL_DOWNLOAD_DOCX: undefined,
  RESERVATION_DETAIL_DOWNLOAD_DOCX_SUCCESS: undefined,
  RESERVATION_DETAIL_DOWNLOAD_DOCX_FAILURE: undefined,
  TRANSACTION_INVOICE_DOWNLOAD_PDF: undefined,
  TRANSACTION_INVOICE_DOWNLOAD_PDF_SUCCESS: undefined,
  TRANSACTION_INVOICE_DOWNLOAD_PDF_FAILURE: undefined,
  BRANCH_HEADER_GET: undefined,
  BRANCH_HEADER_GET_FINISH: undefined,
  RESERVATION_GET_BY_FOLIO: undefined,
  RESERVATION_GET_BY_FOLIO_FINISH: undefined,
  RESERVATION_GET_BY_FOLIO_RESET: undefined,
  TRANSACTION_CHANGE_ROOM: undefined,
  TRANSACTION_CHANGE_ROOM_SUCCESS: undefined,
  GET_BRANCH: undefined,
  GET_BRANCH_FINISH: undefined,
  GET_BRANCH_FACILITY: undefined,
  GET_BRANCH_FACILITY_FINISH: undefined,
  BRANCH_SELECTED: undefined,
  GET_NOTIFICATION: undefined,
  GET_NOTIFICATION_FINISH: undefined,
  READ_NOTIFICATION: undefined,
  READ_NOTIFICATION_FINISH: undefined,
  GET_RESERVATION_ROOM_INHOUSE: undefined,
  GET_RESERVATION_ROOM_INHOUSE_FINISH: undefined,
  GET_RESERVATION_ROOM_CHECKOUT_TODAY: undefined,
  GET_RESERVATION_ROOM_CHECKOUT_TODAY_FINISH: undefined,
  GET_RESERVATION_ROOM_CHECKIN_TODAY: undefined,
  GET_RESERVATION_ROOM_CHECKIN_TODAY_FINISH: undefined,
  RESERVATION_DETAIL_PRINT_REGISTRATION_CARD_PDF: undefined,
  RESERVATION_DETAIL_PRINT_REGISTRATION_CARD_PDF_SUCCESS: undefined,
  RESERVATION_DETAIL_PRINT_REGISTRATION_CARD_PDF_FAILURE: undefined,
  RESERVATION_DETAIL_PRINT_DEPOSIT_PDF: undefined,
  RESERVATION_DETAIL_PRINT_DEPOSIT_PDF_SUCCESS: undefined,
  RESERVATION_DETAIL_PRINT_DEPOSIT_PDF_FAILURE: undefined,
  RESERVATION_DETAIL_PRINT_CHECKIN_CONFIRM_PDF: undefined,
  RESERVATION_DETAIL_PRINT_CHECKIN_CONFIRM_PDF_SUCCESS: undefined,
  DASHBOARD_GET_FUEL_INFO: undefined,
  DASHBOARD_GET_FUEL_INFO_FINISH: undefined,
  DASHBOARD_GET_ELECTRIC_YESTERDAY: undefined,
  DASHBOARD_GET_ELECTRIC_YESTERDAY_FINISH: undefined,
  DASHBOARD_GET_WATER_YESTERDAY: undefined,
  DASHBOARD_GET_WATER_YESTERDAY_FINISH: undefined,
  DASHBOARD_GET_ELECTRIC_AREA: undefined,
  DASHBOARD_GET_ELECTRIC_AREA_FINISH: undefined,
  DASHBOARD_GET_ELECTRIC_POWER: undefined,
  DASHBOARD_GET_ELECTRIC_POWER_FINISH: undefined,
  DASHBOARD_GET_DURATION_CURVE: undefined,
  DASHBOARD_GET_DURATION_CURVE_FINISH: undefined,
  DASHBOARD_GET_WATER_AREA: undefined,
  DASHBOARD_GET_WATER_AREA_FINISH: undefined,
  POWER_MONITOR_GET_DEVICE_MANAGER: undefined,
  POWER_MONITOR_GET_DEVICE_MANAGER_FINISH: undefined,
  NIGHT_AUDIT_NO_SHOW: undefined,
  NIGHT_AUDIT_NO_SHOW_FINISH: undefined,
  NIGHT_AUDIT_HANDLE: undefined,
  NIGHT_AUDIT_HANDLE_FINISH: undefined,
  CHECKIN: undefined,
  CHECKIN_SUCCESS: undefined,
  ADD_LATE_CHECKOUT_FEE: undefined,
  ADD_LATE_CHECKOUT_FEE_SUCCESS: undefined,
  CHECKOUT: undefined,
  CHECKOUT_SUCCESS: undefined,
  BRANCH_MANAGER_GET: undefined,
  BRANCH_MANAGER_GET_FINISH: undefined,
  BRANCH_MANAGER_DOWNLOAD_CSV: undefined,
  BRANCH_MANAGER_DOWNLOAD_CSV_FINISH: undefined,
  HOUSE_KEEPING_GET: undefined,
  HOUSE_KEEPING_GET_FINISH: undefined,
  UPDATE_HOUSE_KEEPING: undefined,
  UPDATE_HOUSE_KEEPING_SUCCESS: undefined,
  RESET_RESERVATION_ROOM_INHOUSE_FILTER: undefined,
  RESET_RESERVATION_ROOM_CHECKOUT_FILTER: undefined,
  RESET_RESERVATION_ROOM_CHECKIN_FILTER: undefined,
  RESERVATION_DETAIL_PAYMENT_VNPAY: undefined,
  RESERVATION_DETAIL_PAYMENT_VNPAY_SUCCESS: undefined,
  RESERVATION_DETAIL_PAYMENT_MOMOPAY: undefined,
  RESERVATION_DETAIL_PAYMENT_MOMOPAY_SUCCESS: undefined,
  GET_RESERVATION_DETAIL_BY_ROOM_NO: undefined,
  GET_RESERVATION_DETAIL_BY_ROOM_NO_SUCCESS: undefined,
  RESET_RESERVATION_DETAIL_BY_ROOM_NO_SUCCESS: undefined,
  CREATE_QRCODE_VNPAY: undefined,
  CREATE_QRCODE_VNPAY_SUCCESS: undefined,
  GET_CUSTOMER_DETAIL: undefined,
  GET_CUSTOMER_DETAIL_SUCCESS: undefined,
  GET_RESERVATION_CHECKIN: undefined,
  GET_RESERVATION_CHECKIN_SUCCESS: undefined,
  RESET_RESERVATION_CHECKIN: undefined,
  GET_MONTHLY_INVOICE: undefined,
  GET_MONTHLY_INVOICE_FINISH: undefined,
  PAYMENT_MONTHLY_INVOICE: undefined,
  PAYMENT_MONTHLY_INVOICE_SUCCESS: undefined,
  UPDATE_PAYMENT_DETAIL: undefined,
  UPDATE_PAYMENT_DETAIL_SUCCESS: undefined,
  GET_FACILITY: undefined,
  GET_FACILITY_FINISH: undefined,
});

export const STATUS: Status = {
  IDLE: 'idle',
  RUNNING: 'running',
  READY: 'ready',
  SUCCESS: 'success',
  ERROR: 'error',
};
