/** ***********************************
Module Name : Front Desk
Developer Name : MinhNV
Created Date : 01/12/2022
Updated Date : 02/12/2022
Main functions : RoomInfo Card
************************************ */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Card, Col, Row } from 'antd';
import moment from 'moment';
import { selectBranchInfo } from 'selectors';

import { useAppSelector } from 'modules/hooks';

import { searchRoomReset } from 'actions';

import { BranchInfoState } from 'types';

interface Props {
  item: any;
  setCurrentRoom: (value: any) => void;
  setIsModalVisible: (value: boolean) => void;
}

function RoomInfo({ item, setCurrentRoom, setIsModalVisible }: Props) {
  const branchInfoSelected: BranchInfoState = useAppSelector(selectBranchInfo);

  const { t } = useTranslation();
  const isReadyItem =
    item.state?.toString() === '1' &&
    branchInfoSelected.business_date === moment().format('YYYY-MM-DD');
  const dispatch = useDispatch();

  const viewMapping = {
    '256': t('common.Ocean View'),
    '512': t('common.Mountain View'),
  };

  const directionMapping = {
    '16': t('common.North Direction'),
    '32': t('common.East Direction'),
    '64': t('common.West Direction'),
    '128': t('common.South Direction'),
  };

  const wingMapping = {
    '1': t('common.Northwing'),
    '2': t('common.Eastwing'),
    '4': t('common.Westwing'),
    '8': t('common.Southwing'),
  };

  const roomStatusMapping = {
    '1': (
      <>
        <span style={{ paddingRight: 10 }}>{t('frontDesk.Ready')}</span>
        <svg
          fill="none"
          height="20"
          style={{ position: 'absolute', top: 4 }}
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M10 0C4.57143 0 0 4.57143 0 10C0 15.4286 4.57143 20 10 20C15.4286 20 20 15.4286 20 10C20 4.57143 15.4286 0 10 0ZM5.20437 10.7938C4.93237 10.5043 4.93181 10.0356 5.2031 9.74529C5.47594 9.45334 5.9201 9.45251 6.19391 9.74343L8.33618 12.0196L13.8027 6.21801C14.0754 5.92864 14.5167 5.92715 14.7911 6.21466C15.0684 6.50524 15.0698 6.98021 14.7943 7.27269L8.54158 13.9098C8.42815 14.0302 8.24434 14.0301 8.13108 13.9095L5.20437 10.7938Z"
            fill="#52C41A"
            fillRule="evenodd"
          />
        </svg>
      </>
    ),
    '2': (
      <>
        <span style={{ paddingRight: 10 }}>{t('frontDesk.Cleaning')}</span>
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.87725 14.1527C10.1975 14.1527 10.4573 13.8931 10.4573 13.5727C10.4573 13.2523 10.1975 12.9926 9.87725 12.9926C9.55687 12.9926 9.2972 13.2523 9.2972 13.5727C9.2972 13.893 9.55687 14.1527 9.87725 14.1527Z"
            fill="#307DFF"
          />
          <path
            clipRule="evenodd"
            d="M16 14.4963V8.92365C16 6.74824 14.3898 4.94416 12.3054 4.65803V4.27463C12.3054 3.63763 11.9074 3.09277 11.3477 2.87395C11.1154 1.25136 9.71679 0 8.03079 0H6.12192C4.27404 0 2.77094 1.5031 2.77094 3.35099V8.46023C2.22917 8.68763 1.84729 9.22326 1.84729 9.84729V12.9926H1.50369C0.674468 12.9926 0 13.6671 0 14.4963V15.42C0 15.7403 0.25967 16 0.580049 16H6.12192C6.4423 16 6.70197 15.7403 6.70197 15.42V14.4963C6.70197 13.6671 6.0275 12.9926 5.19828 12.9926H4.85468V9.84729C4.85468 9.22326 4.4728 8.68763 3.93103 8.46023V3.35099C3.93103 2.14302 4.91396 1.1601 6.12192 1.1601H8.03079C9.08668 1.1601 9.97059 1.91141 10.1762 2.9076C9.65878 3.14524 9.29803 3.66815 9.29803 4.27463V4.73486C8.75626 4.96226 8.37438 5.49789 8.37438 6.12192V11.6685C7.81249 12.1132 7.45074 12.8013 7.45074 13.5727C7.45074 14.9112 8.53952 16 9.87808 16H14.4963C15.3255 16 16 15.3255 16 14.4963ZM5.54187 14.4963V14.8399H1.1601V14.4963C1.1601 14.307 1.31439 14.1527 1.50369 14.1527H5.19828C5.38759 14.1527 5.54187 14.307 5.54187 14.4963ZM3.00739 12.9926V9.84729C3.00739 9.65798 3.16168 9.50369 3.35099 9.50369C3.54029 9.50369 3.69458 9.65798 3.69458 9.84729V12.9926H3.00739ZM10.8017 3.93103C10.991 3.93103 11.1453 4.08532 11.1453 4.27463V4.61823H10.4581V4.27463C10.4581 4.08532 10.6124 3.93103 10.8017 3.93103ZM8.61084 13.5727C8.61084 12.874 9.17944 12.3054 9.87808 12.3054C10.5767 12.3054 11.1453 12.874 11.1453 13.5727C11.1453 14.2713 10.5767 14.8399 9.87808 14.8399C9.17944 14.8399 8.61084 14.2713 8.61084 13.5727ZM14.8399 11.351V14.4963C14.8399 14.6856 14.6856 14.8399 14.4963 14.8399H11.9476C12.1744 14.4709 12.3054 14.0367 12.3054 13.5727C12.3054 12.2341 11.2166 11.1453 9.87808 11.1453C9.76149 11.1453 9.64678 11.1536 9.53448 11.1696V6.12192C9.53448 5.93261 9.68877 5.77833 9.87808 5.77833H11.1453V9.84729C11.1453 10.6765 11.8198 11.351 12.649 11.351H14.8399ZM14.8399 10.1909H12.649C12.4597 10.1909 12.3054 10.0366 12.3054 9.84729V5.83324C13.7457 6.10836 14.8399 7.38887 14.8399 8.92365V10.1909Z"
            fill="#307DFF"
            fillRule="evenodd"
          />
        </svg>
      </>
    ),
    '3': (
      <>
        <span style={{ paddingRight: 10 }}>{t('frontDesk.Dirty')}</span>
        <svg
          fill="none"
          height="20"
          style={{ position: 'absolute', top: 4 }}
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M0 10C0 4.57143 4.57143 0 10 0C15.4286 0 20 4.57143 20 10C20 15.4286 15.4286 20 10 20C4.57143 20 0 15.4286 0 10ZM9 5C9 4.44772 9.44771 4 10 4C10.5523 4 11 4.44772 11 5V11C11 11.5523 10.5523 12 10 12C9.44771 12 9 11.5523 9 11V5ZM9 15C9 14.4477 9.44771 14 10 14C10.5523 14 11 14.4477 11 15C11 15.5523 10.5523 16 10 16C9.44771 16 9 15.5523 9 15Z"
            fill="#FAAD14"
            fillOpacity="0.85"
            fillRule="evenodd"
          />
        </svg>
      </>
    ),
    '4': (
      <>
        <span style={{ paddingRight: 10 }}>{t('frontDesk.Inhouse')}</span>
        <svg
          fill="none"
          height="16"
          viewBox="0 0 16 16"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M14.72 6.93399V13.52C14.72 14.1827 14.1827 14.72 13.52 14.72H12.0099V12.8C12.0099 11.4745 10.9353 10.4 9.60986 10.4H8.00986C9.33535 10.4 10.4099 9.32548 10.4099 8C10.4099 6.67452 9.33535 5.6 8.00986 5.6C6.68438 5.6 5.60986 6.67452 5.60986 8C5.60986 9.32548 6.68438 10.4 8.00986 10.4H6.40986C5.08438 10.4 4.00986 11.4745 4.00986 12.8V14.72H2.48C1.81726 14.72 1.28 14.1827 1.28 13.52V2.48C1.28 1.81726 1.81726 1.28 2.48 1.28H3.92C4.58274 1.28 5.12 1.81726 5.12 2.48V4.48H9.00918C8.87353 4.07812 8.8 3.64765 8.8 3.2H6.4V2.4C6.4 1.07452 5.32548 0 4 0H2.4C1.07452 0 0 1.07452 0 2.4V13.6C0 14.9255 1.07452 16 2.4 16H13.6C14.9255 16 16 14.9255 16 13.6V5.91599C15.8211 6.22364 15.1793 6.8 14.72 6.93399ZM10.8099 14.72H5.20986V12.8C5.20986 12.1373 5.74712 11.6 6.40986 11.6H9.60986C10.2726 11.6 10.8099 12.1373 10.8099 12.8V14.72ZM8.00986 9.2C8.6726 9.2 9.20986 8.66274 9.20986 8C9.20986 7.33726 8.6726 6.8 8.00986 6.8C7.34712 6.8 6.80986 7.33726 6.80986 8C6.80986 8.66274 7.34712 9.2 8.00986 9.2Z"
            fill="#52C41A"
            fillRule="evenodd"
          />
          <path
            d="M12.0099 2.89773L12.3828 3.27071L13.5923 2.0613C13.6884 1.96518 13.811 1.91753 13.9409 1.91453C14.0792 1.91158 14.2073 1.96243 14.3046 2.06446C14.4014 2.16295 14.4461 2.28792 14.4461 2.41805C14.4461 2.54963 14.4004 2.67588 14.3015 2.7748L12.7353 4.34097C12.6879 4.38843 12.63 4.42829 12.5614 4.45354C12.5043 4.47464 12.4443 4.48558 12.3828 4.48558C12.3215 4.48558 12.2615 4.47466 12.2045 4.45361C12.1358 4.42837 12.0779 4.38848 12.0304 4.34097L11.2964 3.60696C11.197 3.50756 11.151 3.38001 11.154 3.24609C11.1569 3.11604 11.2046 2.99374 11.3006 2.89773C11.3984 2.79999 11.5233 2.75311 11.6553 2.75311C11.7874 2.75311 11.9122 2.80009 12.0099 2.89773Z"
            fill="#52C41A"
          />
          <path
            clipRule="evenodd"
            d="M12.8 6.4C14.5673 6.4 16 4.96731 16 3.2C16 1.43269 14.5673 0 12.8 0C11.0327 0 9.6 1.43269 9.6 3.2C9.6 4.96731 11.0327 6.4 12.8 6.4ZM14.9609 3.2C14.9609 4.39344 13.9934 5.36092 12.8 5.36092C11.6066 5.36092 10.6391 4.39344 10.6391 3.2C10.6391 2.00656 11.6066 1.03908 12.8 1.03908C13.9934 1.03908 14.9609 2.00656 14.9609 3.2Z"
            fill="#52C41A"
            fillRule="evenodd"
          />
        </svg>
      </>
    ),
    '5': (
      <>
        <span style={{ paddingRight: 10 }}>{t('frontDesk.Inspect')}</span>
        <svg
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M0 10C0 4.57143 4.57143 0 10 0C15.5228 0 20 4.47715 20 10C20 15.4286 15.4286 20 10 20C4.57143 20 0 15.4286 0 10ZM10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10H10V3Z"
            fill="#9254DE"
            fillRule="evenodd"
          />
        </svg>
      </>
    ),
  };

  const showWalkinCheckinModal = () => {
    dispatch(searchRoomReset());
    setIsModalVisible(true);
    setCurrentRoom(item);
  };

  return (
    <Col span={8} style={{ paddingTop: 25, paddingLeft: 8, paddingRight: 8 }}>
      <Card
        actions={[
          <>
            {' '}
            {!isReadyItem ? (
              <svg
                fill="none"
                height="24"
                style={{ position: 'absolute', left: '37%' }}
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect fill="#DFD4FF" height="24" opacity="0.01" width="24" />
                <rect fill="#885EFE" height="20" opacity="0.01" width="20" x="2" y="2" />
                <path
                  clipRule="evenodd"
                  d="M20.1575 2.00638C20.1663 2.00906 20.1751 2.01174 20.1847 2.0125C21.1622 1.93333 21.9997 2.695 21.9997 3.66667V18.6667C21.9997 19.3792 21.5463 20.015 20.8705 20.2483L15.8638 21.9175C15.6805 21.9742 15.5113 22 15.333 22C14.4138 22 13.6663 21.2525 13.6663 20.3333V19.5H11.1663C9.78802 19.5 8.66635 18.3783 8.66635 17V15.3333C8.66635 14.8733 9.03968 14.5 9.49968 14.5C9.95968 14.5 10.333 14.8733 10.333 15.3333V17C10.333 17.4592 10.7072 17.8333 11.1663 17.8333H13.6663V5.33333C13.6663 4.62083 14.1197 3.985 14.7947 3.75167L15.0488 3.66667H11.1663C10.7072 3.66667 10.333 4.04083 10.333 4.5V5.33333C10.333 5.79333 9.95968 6.16667 9.49968 6.16667C9.03968 6.16667 8.66635 5.79333 8.66635 5.33333V4.5C8.66635 3.12167 9.78802 2 11.1663 2H20.1247C20.1364 2 20.147 2.0032 20.1575 2.00638ZM7.58917 6.41085L10.9225 9.74418C11.2483 10.07 11.2483 10.5967 10.9225 10.9225L7.58917 14.2558C7.35083 14.4942 6.9925 14.5658 6.68083 14.4367C6.37 14.3075 6.16667 14.0033 6.16667 13.6667V11.1667H2.83333C2.37333 11.1667 2 10.7933 2 10.3333C2 9.87335 2.37333 9.50001 2.83333 9.50001H6.16667V7.00001C6.16667 6.66335 6.37 6.35918 6.68083 6.23001C6.9925 6.10085 7.35083 6.17251 7.58917 6.41085Z"
                  fill="#c3c3c3"
                  fillRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                fill="none"
                height="20"
                onClick={showWalkinCheckinModal}
                style={{ position: 'relative', top: 4, marginRight: -10 }}
                viewBox="0 0 20 20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M18.1575 0.00638228C18.1663 0.00906338 18.1751 0.0117385 18.1847 0.0125C19.1622 -0.0666667 19.9997 0.695 19.9997 1.66667V16.6667C19.9997 17.3792 19.5463 18.015 18.8705 18.2483L13.8638 19.9175C13.6805 19.9742 13.5113 20 13.333 20C12.4138 20 11.6663 19.2525 11.6663 18.3333V17.5H9.16635C7.78802 17.5 6.66635 16.3783 6.66635 15V13.3333C6.66635 12.8733 7.03968 12.5 7.49968 12.5C7.95968 12.5 8.33301 12.8733 8.33301 13.3333V15C8.33301 15.4592 8.70718 15.8333 9.16635 15.8333H11.6663V3.33333C11.6663 2.62083 12.1197 1.985 12.7947 1.75167L13.0488 1.66667H9.16635C8.70718 1.66667 8.33301 2.04083 8.33301 2.5V3.33333C8.33301 3.79333 7.95968 4.16667 7.49968 4.16667C7.03968 4.16667 6.66635 3.79333 6.66635 3.33333V2.5C6.66635 1.12167 7.78802 0 9.16635 0H18.1247C18.1364 0 18.147 0.00319536 18.1575 0.00638228ZM5.58917 4.41085L8.9225 7.74418C9.24833 8.07001 9.24833 8.59668 8.9225 8.92251L5.58917 12.2558C5.35083 12.4942 4.9925 12.5658 4.68083 12.4367C4.37 12.3075 4.16667 12.0033 4.16667 11.6667V9.16668H0.833333C0.373333 9.16668 0 8.79335 0 8.33335C0 7.87335 0.373333 7.50001 0.833333 7.50001H4.16667V5.00001C4.16667 4.66335 4.37 4.35918 4.68083 4.23001C4.9925 4.10085 5.35083 4.17251 5.58917 4.41085Z"
                  fill="#1D39C4"
                  fillRule="evenodd"
                />
              </svg>
            )}
            {isReadyItem ? (
              <span
                aria-hidden="true"
                onClick={showWalkinCheckinModal}
                style={{ color: '#1D39C4', fontSize: 14, paddingLeft: 20 }}
              >
                {t('frontDesk.Check In')}
              </span>
            ) : (
              <span
                aria-hidden="true"
                style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: 14, paddingLeft: 20 }}
              >
                {t('frontDesk.Check In')}
              </span>
            )}
          </>,
        ]}
        style={{ paddingLeft: 8, paddingRight: 8 }}
      >
        <Row>
          <Col span={12}>
            <div
              style={{
                background: '#1d39c41a',
                borderRadius: 2,
                borderLeft: '5px solid #1d39c4',
                height: 'fit-content',
                textAlign: 'left',
                paddingTop: 4,
                paddingRight: 15,
                paddingLeft: 15,
                width: 'fit-content',
              }}
            >
              {' '}
              <span
                style={{
                  color: '#1d39c4',
                  lineHeight: '24px',
                  fontSize: 16,
                }}
              >
                {item.name}
              </span>{' '}
            </div>
          </Col>
          <Col span={12}>
            <div style={{ float: 'right', paddingTop: 4 }}>
              {item.state === null && t('common.Not yet setting')}
              {item.state && item.state.toString() === '1' && roomStatusMapping['1']}
              {item.state && item.state.toString() === '2' && roomStatusMapping['2']}
              {item.state && item.state.toString() === '3' && roomStatusMapping['3']}
              {item.state && item.state.toString() === '4' && roomStatusMapping['4']}
              {item.state && item.state.toString() === '5' && roomStatusMapping['5']}
            </div>
          </Col>
          <Col span={12} style={{ paddingTop: 20 }}>
            <svg
              fill="none"
              height="16"
              viewBox="0 0 16 16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.239 8C9.88559 8 9.59905 8.28654 9.59905 8.64C9.59905 8.99346 9.88559 9.28 10.239 9.28H12.159C12.5125 9.28 12.799 8.99346 12.799 8.64C12.799 8.28654 12.5125 8 12.159 8H10.239Z"
                fill="black"
                fillOpacity="0.45"
              />
              <path
                d="M10.239 11.2C9.88559 11.2 9.59905 11.4865 9.59905 11.84C9.59905 12.1935 9.88559 12.48 10.239 12.48H12.159C12.5125 12.48 12.799 12.1935 12.799 11.84C12.799 11.4865 12.5125 11.2 12.159 11.2H10.239Z"
                fill="black"
                fillOpacity="0.45"
              />
              <path
                clipRule="evenodd"
                d="M2.4 16C1.07452 16 0 14.9255 0 13.6V2.4C0 1.07452 1.07452 0 2.4 0H5.6C6.92548 0 8 1.07452 8 2.4V4.76362H13.6C14.9255 4.76362 16 5.83814 16 7.16362V13.6C16 14.9255 14.9255 16 13.6 16H2.4ZM1.28 2.4C1.28 1.78144 1.78144 1.28 2.4 1.28H5.6C6.21856 1.28 6.72 1.78144 6.72 2.4V4.76362H1.28V2.4ZM1.28 6.04362V8H6.72V6.04362H1.28ZM2.4 14.72C1.78144 14.72 1.28 14.2186 1.28 13.6V12.48H6.72V14.72H2.4ZM6.72 11.2H1.28V9.28H6.72V11.2ZM8 6.04362V14.72H13.6C14.2186 14.72 14.72 14.2186 14.72 13.6V7.16362C14.72 6.54506 14.2186 6.04362 13.6 6.04362H8Z"
                fill="black"
                fillOpacity="0.45"
                fillRule="evenodd"
              />
            </svg>
            {item && item.wing !== undefined && item.wing !== null && (
              <span style={{ paddingLeft: 6 }}>
                {item.wing === 0 && t('common.Not yet setting')}
                {item.wing.toString() === '1' && wingMapping['1']}
                {item.wing.toString() === '2' && wingMapping['2']}
                {item.wing.toString() === '4' && wingMapping['4']}
                {item.wing.toString() === '8' && wingMapping['8']}
              </span>
            )}
          </Col>
          <Col span={12} style={{ paddingTop: 20 }}>
            <span style={{ float: 'right', color: 'rgba(0, 0, 0, 0.45)' }}>
              {t('common.Room Type')}
            </span>
          </Col>

          <Col span={12} style={{ paddingTop: 19 }}>
            <div>
              <svg
                fill="none"
                height="10"
                viewBox="0 0 14 10"
                width="14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.6121 3.42749C6.73637 3.39697 6.86619 3.38066 6.99986 3.38066C7.89425 3.38066 8.61924 4.10571 8.61924 5.00008C8.61924 5.89443 7.89421 6.61947 6.99984 6.61947C6.10549 6.61947 5.38045 5.89445 5.38045 5.00008C5.38045 4.86639 5.39677 4.73657 5.42729 4.61233C5.57456 4.7396 5.76636 4.81673 5.97628 4.81673C6.44035 4.81673 6.81653 4.44053 6.81653 3.97648C6.81653 3.76656 6.73936 3.57477 6.6121 3.42749Z"
                  fill="black"
                  fillOpacity="0.45"
                />
                <path
                  clipRule="evenodd"
                  d="M7.00018 0.857422C9.85653 0.857422 12.341 2.4495 13.6147 4.79468C13.6842 4.92265 13.6842 5.07745 13.6147 5.20542C12.341 7.55058 9.85653 9.1427 7.00014 9.1427C4.14378 9.1427 1.65936 7.55058 0.38562 5.20542C0.316121 5.07745 0.316121 4.92265 0.38562 4.79468C1.65934 2.44954 4.14382 0.857422 7.00018 0.857422ZM3.82249 5.00006C3.82249 6.75503 5.24516 8.17773 7.00014 8.17773C8.75515 8.17773 10.1778 6.75505 10.1778 5.00006C10.1778 3.24508 8.75515 1.82237 7.00014 1.82237C5.24516 1.82237 3.82249 3.2451 3.82249 5.00006Z"
                  fill="black"
                  fillOpacity="0.45"
                  fillRule="evenodd"
                />
              </svg>
              {item && item.view !== undefined && item.view !== null && (
                <span style={{ paddingLeft: 6 }}>
                  {item.view === 0 && t('common.Not yet setting')}
                  {item.view.toString() === '256' && viewMapping['256']}
                  {item.view.toString() === '512' && viewMapping['512']}
                </span>
              )}
            </div>

            <div style={{ paddingTop: 20 }}>
              <svg
                fill="none"
                height="14"
                viewBox="0 0 12 14"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M8.05121 9.32795C7.36548 9.97721 6.63714 10.6668 5.99975 11.5363C5.36266 10.6671 4.63442 9.97756 3.9487 9.32825C2.59852 8.04979 1.41321 6.92742 1.41321 4.89733C1.41321 2.28184 3.37239 0.333008 5.99975 0.333008C8.62795 0.333008 10.5863 2.28184 10.5863 4.8973C10.5863 6.92766 9.40137 8.04958 8.05121 9.32795ZM7.25063 5.06884C7.25063 4.38309 6.68882 3.82402 5.99975 3.82402C5.31063 3.82402 4.74887 4.38309 4.74887 5.06884C4.74887 5.75431 5.31063 6.31366 5.99975 6.31366C6.68882 6.31366 7.25063 5.75431 7.25063 5.06884Z"
                  fill="black"
                  fillOpacity="0.45"
                  fillRule="evenodd"
                />
                <path
                  d="M8.33494 12.2279H11.7796L11.966 12.7928C12.1083 13.2237 11.786 13.6663 11.3303 13.6663H0.669789C0.214062 13.6663 -0.108269 13.2236 0.0339527 12.7927L0.220399 12.2279H3.66503V11.398H0.49433L0.996502 9.87657H3.38913C3.4068 9.89335 3.42445 9.91012 3.44209 9.92688C4.12154 10.5724 4.78081 11.1987 5.36574 11.9967L5.99993 12.862L6.6342 11.9967C7.2209 11.1964 7.87489 10.5755 8.58176 9.90427L8.61094 9.87657H11.0035L11.5056 11.398H8.33494V12.2279Z"
                  fill="black"
                  fillOpacity="0.45"
                />
              </svg>

              {item && item.direction !== undefined && item.direction !== null && (
                <span style={{ paddingLeft: 8 }}>
                  {item.direction === 0 && t('common.Not yet setting')}
                  {item.direction.toString() === '16' && directionMapping['16']}
                  {item.direction.toString() === '32' && directionMapping['32']}
                  {item.direction.toString() === '64' && directionMapping['64']}
                  {item.direction.toString() === '128' && directionMapping['128']}
                </span>
              )}
            </div>
            <div style={{ paddingTop: 20, paddingBottom: 5 }}>
              {item.is_smoking ? (
                <svg
                  fill="none"
                  height="12"
                  viewBox="0 0 14 12"
                  width="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5668 4.04469C11.9768 3.61763 12.2335 3.02118 12.2335 2.36471C12.2335 1.05882 11.2301 0 10.0001 0V1.05882C10.6801 1.05882 11.2335 1.64472 11.2335 2.36471C11.2335 3.08472 10.6801 3.6706 10.0001 3.6706V4.72942C11.4968 4.72942 12.6668 6.01764 12.6668 7.60239V9.17649H13.6668V7.59883C13.6668 6.02824 12.8135 4.67296 11.5668 4.04469Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                  <path
                    d="M9.68672 5.78471H8.66671V5.78827C7.98671 5.78827 7.43337 5.09649 7.43337 4.37648C7.43337 3.65649 7.98671 3.14121 8.66671 3.14121V2.08238C7.43671 2.08238 6.43337 3.14121 6.43337 4.44709C6.43337 5.75298 7.43337 6.8118 8.66671 6.8118V6.80827H9.68672C10.3834 6.80827 11.0001 7.33064 11.0001 8.25885V9.17649H12.0001V8.0153C12 6.7447 10.9334 5.78471 9.68672 5.78471Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                  <path
                    d="M12.0001 9.88235H11.0001V12H12.0001V9.88235Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                  <path
                    d="M0.333496 9.88235H10.3335V12H0.333496V9.88235Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                  <path
                    d="M12.6668 9.88235H13.6668V12H12.6668V9.88235Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                </svg>
              ) : (
                <svg
                  fill="none"
                  height="14"
                  viewBox="0 0 14 14"
                  width="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3545 11.7228L9.99093 8.35919H10.7181V5.53868H7.17041L2.27759 0.645854C2.20549 0.57373 2.10767 0.533203 2.00566 0.533203C1.90365 0.533203 1.80583 0.573751 1.73368 0.645854L0.645874 1.73372C0.495646 1.88393 0.495646 2.12744 0.645874 2.27765L11.7228 13.3546C11.7949 13.4267 11.8927 13.4672 11.9948 13.4672C12.0968 13.4672 12.1946 13.4267 12.2667 13.3546L13.3545 12.2667C13.5047 12.1165 13.5047 11.873 13.3545 11.7228Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                  <path
                    d="M12.5642 2.33358C12.5642 2.21208 12.5972 2.17096 12.6851 2.06101C12.8004 1.91691 12.9745 1.69924 12.9745 1.3079C12.9745 1.09548 12.8023 0.923299 12.5899 0.923299C12.3775 0.923299 12.2053 1.09552 12.2053 1.3079C12.2053 1.42942 12.1724 1.47054 12.0844 1.58048C11.9692 1.72455 11.795 1.94226 11.795 2.33358C11.795 2.72491 11.9692 2.94263 12.0844 3.08669C12.1724 3.19663 12.2053 3.23775 12.2053 3.35927C12.2053 3.48071 12.1724 3.52185 12.0844 3.63175C11.9692 3.77582 11.795 3.99351 11.795 4.38481C11.795 4.59723 11.9672 4.76943 12.1796 4.76943C12.392 4.76943 12.5642 4.59721 12.5642 4.38481C12.5642 4.26335 12.5972 4.22223 12.6851 4.1123C12.8003 3.96824 12.9745 3.75057 12.9745 3.35927C12.9745 2.96794 12.8003 2.7502 12.6851 2.60616C12.5972 2.49622 12.5642 2.4551 12.5642 2.33358Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                  <path
                    d="M2.81901 5.53868H0.718099C0.505697 5.53868 0.333496 5.71088 0.333496 5.92328V7.97455C0.333496 8.18697 0.505697 8.35915 0.718099 8.35915H5.63953L2.81901 5.53868Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                  <path
                    d="M11.1467 2.06101C11.0588 2.17096 11.0259 2.21208 11.0259 2.33358C11.0259 2.4551 11.0588 2.49622 11.1467 2.60614C11.2619 2.75018 11.4361 2.96792 11.4361 3.35923C11.4361 3.75055 11.2619 3.96822 11.1467 4.11228C11.0588 4.22221 11.0258 4.26333 11.0258 4.38479C11.0258 4.59719 10.8536 4.76939 10.6412 4.76939C10.4288 4.76939 10.2566 4.59721 10.2566 4.38479C10.2566 3.99349 10.4308 3.7758 10.546 3.63173C10.634 3.52181 10.6669 3.48069 10.6669 3.35923C10.6669 3.23773 10.634 3.19661 10.546 3.08665C10.4308 2.94261 10.2566 2.72489 10.2566 2.33356C10.2566 1.94224 10.4308 1.72453 10.546 1.58046C10.634 1.47052 10.6669 1.4294 10.6669 1.30788C10.6669 1.09548 10.8391 0.923299 11.0515 0.923299C11.2639 0.923299 11.4361 1.09548 11.4361 1.3079C11.4361 1.69924 11.262 1.91691 11.1467 2.06101Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                  <path
                    d="M11.4876 5.53868H13.2825C13.4949 5.53868 13.6671 5.71088 13.6671 5.9233V7.97457C13.6671 8.18697 13.4949 8.35919 13.2825 8.35919H11.4876V5.53868Z"
                    fill="black"
                    fillOpacity="0.45"
                  />
                </svg>
              )}

              <span style={{ paddingLeft: 6 }}>
                {' '}
                {item.is_smoking ? t('frontDesk.Smoking') : t('frontDesk.No Smoking')}
              </span>
            </div>
          </Col>
          <Col span={12} style={{ paddingTop: 12 }}>
            <span style={{ float: 'right', fontSize: 24, textAlign: 'right' }}>{item.type}</span>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}

export default RoomInfo;
