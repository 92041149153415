import paymentMomoPayReservationDetail, {
  paymentMomoPayReservationDetailState,
} from 'reducers/paymentMomoPayReservationDetail';
import printDepositPDFReservationDetail, {
  printDepositPDFReservationDetailState,
} from 'reducers/printDepositPDFReservationDetail';

import addDisk, { addDiskState } from './addDisk';
import addItem, { addItemState } from './addItem';
import addLateCheckoutFee, { addLateCheckoutFeeState } from './addLateCheckoutFee';
import addReservationDetail, { addReservationDetailState } from './addReservationDetail';
import agentInfos, { agentInfosState } from './agentInfos';
import alerts, { alertsState } from './alerts';
import app, { appState } from './app';
import bookRoom, { bookRoomState } from './bookRoom';
import branchInfo, { branchInfoState } from './branchInfo';
import cancelReservationDetail, { cancelReservationDetailState } from './cancelReservationDetail';
import changeDisk, { changeDiskState } from './changeDisk';
import changeRoom, { changeRoomState } from './changeRoom';
import channel, { channelState } from './channel';
import checkin, { checkinState } from './checkin';
import checkout, { checkoutState } from './checkout';
import createGuest, { createGuestState } from './createGuest';
import createPayment, { createPaymentState } from './createPayment';
import createQRCodeVNPay, { createQRCodeVNPayState } from './createQRCodeVNPay';
import createReservation, { createReservationState } from './createReservation';
import customer, { customerSearchState } from './customer';
import deleteItem, { deleteItemState } from './deleteItem';
import downloadCSVBranchManager, {
  downloadCSVBranchManagerState,
} from './downloadCSVBranchManager';
import downloadDocxReservationDetail, {
  downloadDocxReservationDetailState,
} from './downloadDocxReservationDetail';
import downloadPDFInvoiceTransaction, {
  downloadPDFInvoiceTransactionState,
} from './downloadPDFInvoiceTransaction';
import downloadPDFReservationDetail, {
  downloadPDFReservationDetailState,
} from './downloadPDFReservationDetail';
import fuel, { fuelState } from './fuel';
import getBranchFacilites, { getBranchFacilitesState } from './getBranchFacilites';
import getBranchManager, { getBranchManagerState } from './getBranchManager';
import getBranchs, { getBranchsState } from './getBranchs';
import getCustomerDetail, { getCustomerDetailState } from './getCustomerDetail';
import getDeviceManager, { getDeviceManagerState } from './getDeviceManager';
import getDurationCurve, { getDurationCurveState } from './getDurationCurve';
import getElectricArea, { getElectricAreaState } from './getElectricArea';
import getElectricPower, { getElectricPowerState } from './getElectricPower';
import getElectricYesterday, { getElectricYesterdayState } from './getElectricYesterday';
import getFacility, { getFacilityState } from './getFacility';
import getHouseKeeping, { getHouseKeepingState } from './getHouseKeeping';
import getLanguageCode, { getLanguageCodeState } from './getLanguageCode';
import getMonthlyInvoice, { getMonthlyInvoiceState } from './getMonthlyInvoice';
import notifications, { notificationsState } from './getNotification';
import getProductType, { getProductTypeState } from './getProductType';
import getReservation, { getReservationState } from './getReservation';
import getReservationByFolio, { getReservationByFolioState } from './getReservationByFolio';
import getReservationCheckoutFromRoomNo, {
  getReservationCheckoutFromRoomNoState,
} from './getReservationCheckoutFromRoomNo';
import getReservationDetail, { reservationDetailState } from './getReservationDetail';
import getReservationNumber, { reservationNumberState } from './getReservationNumber';
import getRoomOption, { roomOptionState } from './getRoomOption';
import getRooms, { getRoomsState } from './getRooms';
import getRoomType, { roomTypeState } from './getRoomType';
import getWalkinRooms, { getWalkinRoomsState } from './getWalkinRooms';
import getWaterArea, { getWaterAreaState } from './getWaterArea';
import getWaterYesterday, { getWaterYesterdayState } from './getWaterYesterday';
import github, { githubState } from './github';
import guestCheckin, { guestCheckinState } from './guestCheckin';
import nightAudit, { nightAuditState } from './nightAudit';
import noshow, { noshowState } from './noshow';
import paymentMonthlyInvoice, { paymentMonthlyInvoiceState } from './paymentMonthlyInvoice';
import paymentVNPayReservationDetail, {
  paymentVNPayReservationDetailState,
} from './paymentVNPayReservationDetail';
import printCheckinConfirmPDFReservationDetail, {
  printCheckinConfirmPDFReservationDetailState,
} from './printCheckinConfirmPDFReservationDetail';
import printRegistrationCardPDFReservationDetail, {
  printRegistrationCardPDFReservationDetailState,
} from './printRegistrationCardPDFReservationDetail';
import product, { productSearchState } from './product';
import readNotification, { readNotificationState } from './readNotification';
import removeGuest, { removeGuestState } from './removeGuest';
import resendEmailReservation, { resendEmailReservationState } from './resendEmailReservation';
import reservation, { reservationSearchState } from './reservation';
import reservationRoomCheckinToday, {
  reservationRoomCheckinTodayState,
} from './reservationRoomCheckinToday';
import reservationRoomCheckoutToday, {
  reservationRoomCheckoutTodayState,
} from './reservationRoomCheckoutToday';
import reservationRoomInhouse, { reservationRoomInhouseState } from './reservationRoomInhouse';
import searchAvailableSchedule, { searchAvailableScheduleState } from './searchAvailableSchedule';
import searchRoom, { roomSearchState } from './searchRoom';
import searchSchedule, { searchScheduleState } from './searchSchedule';
import setMainGuest, { setMainGuestState } from './setMainGuest';
import updateGeneralInfo, { updateGeneralInfoState } from './updateGeneralInfo';
import updateGuest, { updateGuestState } from './updateGuest';
import updateHouseKeeping, { updateHouseKeepingState } from './updateHouseKeeping';
import updateNoteReservationDetail, {
  updateNoteReservationDetailState,
} from './updateNoteReservationDetail';
import updatePaymentDetail, { updatePaymentDetailState } from './updatePaymentDetail';
import updateRate, { updateRateState } from './updateRate';
import updateReservation, { updateReservationState } from './updateReservation';
import updateRoomAvailable, { updateRoomAvailableState } from './updateRoomAvailable';
import uploadFile, { uploadFileState } from './uploadFile';
import user, { userState } from './user';

export const initialState = {
  alerts: alertsState,
  app: appState,
  addLateCheckoutFee: addLateCheckoutFeeState,
  github: githubState,
  getWalkinRooms: getWalkinRoomsState,
  user: userState,
  reservation: reservationSearchState,
  createReservation: createReservationState,
  updateReservation: updateReservationState,
  searchRoom: roomSearchState,
  getReservationDetail: reservationDetailState,
  product: productSearchState,
  getReservationNumber: reservationNumberState,
  getRoomType: roomTypeState,
  getRoomOption: roomOptionState,
  getReservation: getReservationState,
  getReservationByFolio: getReservationByFolioState,
  getCustomerDetail: getCustomerDetailState,
  agentInfos: agentInfosState,
  bookRoom: bookRoomState,
  updateRate: updateRateState,
  addReservationDetail: addReservationDetailState,
  cancelReservationDetail: cancelReservationDetailState,
  updateGeneralInfo: updateGeneralInfoState,
  uploadFile: uploadFileState,
  searchSchedule: searchScheduleState,
  searchAvailableSchedule: searchAvailableScheduleState,
  getProductType: getProductTypeState,
  channel: channelState,
  checkin: checkinState,
  checkout: checkoutState,
  addItem: addItemState,
  addDisk: addDiskState,
  deleteItem: deleteItemState,
  createPayment: createPaymentState,
  changeDisk: changeDiskState,
  changeRoom: changeRoomState,
  createGuest: createGuestState,
  updateNoteReservationDetail: updateNoteReservationDetailState,
  resendEmailReservation: resendEmailReservationState,
  updateGuest: updateGuestState,
  updateRoomAvailable: updateRoomAvailableState,
  updateHouseKeeping: updateHouseKeepingState,
  removeGuest: removeGuestState,
  readNotification: readNotificationState,
  reservationRoomInhouse: reservationRoomInhouseState,
  reservationRoomCheckoutToday: reservationRoomCheckoutTodayState,
  reservationRoomCheckinToday: reservationRoomCheckinTodayState,
  getLanguageCode: getLanguageCodeState,
  getRooms: getRoomsState,
  getFacility: getFacilityState,
  guestCheckin: guestCheckinState,
  DownloadPDFReservationDetail: downloadPDFReservationDetailState,
  setMainGuest: setMainGuestState,
  downloadDocxReservationDetail: downloadDocxReservationDetailState,
  downloadPDFInvoiceTransaction: downloadPDFInvoiceTransactionState,
  downloadCSVBranchManager: downloadCSVBranchManagerState,
  getBranchs: getBranchsState,
  getBranchManager: getBranchManagerState,
  getBranchFacilities: getBranchFacilitesState,
  branchInfo: branchInfoState,
  notifications: notificationsState,
  noshow: noshowState,
  nightAudit: nightAuditState,
  paymentMonthlyInvoice: paymentMonthlyInvoiceState,
  printRegistrationCardPDFReservationDetail: printRegistrationCardPDFReservationDetailState,
  printCheckinConfirmPDFReservationDetail: printCheckinConfirmPDFReservationDetailState,
  printDepositPDFReservationDetail: printDepositPDFReservationDetailState,
  fuel: fuelState,
  getElectricYesterday: getElectricYesterdayState,
  getHouseKeeping: getHouseKeepingState,
  getWaterYesterday: getWaterYesterdayState,
  getElectricArea: getElectricAreaState,
  getWaterArea: getWaterAreaState,
  getElectricPower: getElectricPowerState,
  getDeviceManager: getDeviceManagerState,
  getDurationCurve: getDurationCurveState,
  getMonthlyInvoice: getMonthlyInvoiceState,
  paymentVNPayReservationDetail: paymentVNPayReservationDetailState,
  paymentMomoPayReservationDetail: paymentMomoPayReservationDetailState,
  getReservationCheckoutFromRoomNo: getReservationCheckoutFromRoomNoState,
  createQRCodeVNPay: createQRCodeVNPayState,
  customer: customerSearchState,
  updatePaymentDetail: updatePaymentDetailState,
};

export default {
  ...alerts,
  ...app,
  ...addLateCheckoutFee,
  ...github,
  ...user,
  ...reservation,
  ...createReservation,
  ...updateReservation,
  ...searchRoom,
  ...getReservationDetail,
  ...getWalkinRooms,
  ...product,
  ...getReservationNumber,
  ...getRoomType,
  ...getRoomOption,
  ...getReservation,
  ...agentInfos,
  ...bookRoom,
  ...updateRate,
  ...addReservationDetail,
  ...cancelReservationDetail,
  ...updateGeneralInfo,
  ...updateHouseKeeping,
  ...uploadFile,
  ...searchSchedule,
  ...searchAvailableSchedule,
  ...getProductType,
  ...channel,
  ...checkin,
  ...checkout,
  ...addDisk,
  ...addItem,
  ...deleteItem,
  ...createPayment,
  ...changeDisk,
  ...changeRoom,
  ...createGuest,
  ...updateNoteReservationDetail,
  ...resendEmailReservation,
  ...updateGuest,
  ...removeGuest,
  ...readNotification,
  ...reservationRoomInhouse,
  ...reservationRoomCheckinToday,
  ...reservationRoomCheckoutToday,
  ...getLanguageCode,
  ...getRooms,
  ...updateRoomAvailable,
  ...downloadPDFReservationDetail,
  ...setMainGuest,
  ...downloadDocxReservationDetail,
  ...downloadPDFInvoiceTransaction,
  ...getReservationByFolio,
  ...getBranchs,
  ...getBranchManager,
  ...getBranchFacilites,
  ...getFacility,
  ...guestCheckin,
  ...branchInfo,
  ...notifications,
  ...noshow,
  ...nightAudit,
  ...paymentMonthlyInvoice,
  ...printRegistrationCardPDFReservationDetail,
  ...printCheckinConfirmPDFReservationDetail,
  ...printDepositPDFReservationDetail,
  ...fuel,
  ...getElectricYesterday,
  ...getHouseKeeping,
  ...getMonthlyInvoice,
  ...getWaterYesterday,
  ...getElectricArea,
  ...getWaterArea,
  ...getElectricPower,
  ...getDeviceManager,
  ...getDurationCurve,
  ...getCustomerDetail,
  ...downloadCSVBranchManager,
  ...paymentVNPayReservationDetail,
  ...paymentMomoPayReservationDetail,
  ...getReservationCheckoutFromRoomNo,
  ...createQRCodeVNPay,
  ...customer,
  ...updatePaymentDetail,
};
