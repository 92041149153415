import { createAction } from '@reduxjs/toolkit';

import { actionPayload } from 'modules/helpers';

import { ActionTypes } from 'literals';

import {
  AddDisk,
  AddItem,
  ChangeDisk,
  ChangeRoom,
  DeleteItem,
  DownloadPDFInvoiceTransaction,
} from 'types';

export const addItemAction = createAction(ActionTypes.TRANSACTION_ADD_ITEM, (payload: AddItem) =>
  actionPayload(payload),
);

export const addItemActionSuccess = createAction(ActionTypes.TRANSACTION_ADD_ITEM_SUCCESS);

export const addDiskAction = createAction(ActionTypes.TRANSACTION_ADD_DISK, (payload: AddDisk) =>
  actionPayload(payload),
);

export const addDiskActionSuccess = createAction(ActionTypes.TRANSACTION_ADD_DISK_SUCCESS);

export const deleteItemAction = createAction(
  ActionTypes.TRANSACTION_DELETE_ITEM,
  (payload: DeleteItem) => actionPayload(payload),
);

export const deleteItemActionSuccess = createAction(ActionTypes.TRANSACTION_DELETE_ITEM_SUCCESS);

export const changeDiskAction = createAction(
  ActionTypes.TRANSACTION_CHANGE_DISK,
  (payload: ChangeDisk) => actionPayload(payload),
);

export const changeDiskActionSuccess = createAction(ActionTypes.TRANSACTION_CHANGE_DISK_SUCCESS);

export const changeRoomAction = createAction(
  ActionTypes.TRANSACTION_CHANGE_ROOM,
  (payload: ChangeRoom) => actionPayload(payload),
);

export const changeRoomActionSuccess = createAction(ActionTypes.TRANSACTION_CHANGE_ROOM_SUCCESS);

export const downloadPDFInvoiceTransaction = createAction(
  ActionTypes.TRANSACTION_INVOICE_DOWNLOAD_PDF,
  (payload: DownloadPDFInvoiceTransaction) => actionPayload(payload),
);

export const downloadPDFInvoiceTransactionSuccess = createAction(
  ActionTypes.TRANSACTION_INVOICE_DOWNLOAD_PDF_SUCCESS,
);
